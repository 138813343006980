<template>
  <v-dialog v-model="dialog" width="748px">
    <template v-slot:activator="{ on }">
      <mf-button text color="marca-0-l40" label="Exportar dados" icon="download" v-on:click.native="on.click" />
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title>
        <span>Exportar dados de ofertas</span>
      </v-card-title>

      <v-card-subtitle>
        Relatório dos dados visualizados na tabela, de acordo com os filtros selecionados.
      </v-card-subtitle>

      <v-card-text class="mt-2">
        <span>Escolha o formato do arquivo a ser exportado:</span>
        <v-radio-group v-model="fileFormat" row>
          <v-radio label="Formato .csv" value="CSV"></v-radio>
          <v-radio label="Formato .xlsx" value="XLSX"></v-radio>
        </v-radio-group>

        <include-emails-table ref="includeEmailsTable" :emails.sync="emails" :max-emails-to-scroll="4" scroll-height="192px" />
      </v-card-text>

      <mf-action-buttons
        class="mb-3 mr-3"
        :primary-button="{
          text: 'Exportar',
          action: exportLog,
          isDisabled: emails.length <= 0,
          isVisible: true,
          isLoading: loading
        }"
        :cancel-button="{
          text: 'Fechar',
          action: () => (dialog = false),
          isDisabled: false,
          isVisible: true,
          isLoading: loading
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import IncludeEmailsTable from './IncludeEmailsTable.vue'
import { MUTATION_INDUSTRY_OFFERS_LOG_REPORT } from '@/modules/industry/graphql'
import { ENUM_MODALITY } from '@/modules/industry/enums'

export default {
  name: 'ExportIndustryOffersData',
  components: {
    IncludeEmailsTable
  },
  props: {
    modality: {
      type: String,
      required: true,
      validator: value => [ENUM_MODALITY.MERCAPROMO, ENUM_MODALITY.MERCATRADE].includes(value)
    },
    search: {
      type: String,
      default: ''
    },
    industryIds: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({
        dates: [],
        actionDates: [],
        actions: [],
        status: [],
        offerTypes: [],
        industries: [],
        smsDates: [],
        proposalDeadline: []
      })
    }
  },
  data: () => ({
    ENUM_MODALITY,
    dialog: false,
    loading: false,
    emails: [],
    fileFormat: 'CSV'
  }),
  mounted() {
    const { username } = this.$getLoggedUser()
    this.emails.push(username)
  },
  methods: {
    async exportLog() {
      this.loading = true

      let queryVariables = {
        emails: this.emails,
        tradeType: [this.modality],
        fileFormat: this.fileFormat,
        offerTypes: this.filters.offerTypes,
        actions: this.filters.actions,
        status: this.filters.status,
        industryIds: this.industryIds,
        search: this.search
      }

      if (this.filters?.dates?.[0] && this.filters?.dates?.[1]) {
        queryVariables.startCampaignPeriod = {
          startDate: this.filters.dates[0],
          endDate: this.filters.dates[1]
        }
      }

      if (this.filters?.actionDates?.[0] && this.filters?.actionDates?.[1]) {
        queryVariables.actionPeriod = {
          startDate: this.filters.actionDates[0],
          endDate: this.filters.actionDates[1]
        }
      }

      if (this.filters?.smsDates?.[0] && this.filters?.smsDates?.[1]) {
        queryVariables.smsSendPeriod = {
          startDate: this.filters.smsDates[0],
          endDate: this.filters.smsDates[1]
        }
      }

      if (this.filters?.proposalDeadline?.[0] && this.filters?.proposalDeadline?.[1]) {
        queryVariables.proposalDeadlinePeriod = {
          startDate: this.filters.proposalDeadline[0],
          endDate: this.filters.proposalDeadline[1]
        }
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INDUSTRY_OFFERS_LOG_REPORT,
          variables: queryVariables,
          context: {
            uri: this.$microservicesUrls['asyncJobs']
          }
        })
        this.$snackbar({ message: 'Arquivo enviado por e-mail.', snackbarColor: 'success' })
        this.dialog = false
      } catch {
        this.$snackbar({ message: 'Erro ao requisitar relatório de dados.', snackbarColor: 'error' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
