<template>
  <div>
    <span class="email-title">Incluir e-mails</span>

    <div class="inputs-container d-flex align-start justify-space-between mb-2">
      <v-text-field v-model="newEmail" :hide-details="!errorEmail" dense :error-messages="errorEmail" placeholder="E-mail" outlined @keyup.enter="addEmail" />
      <v-btn outlined height="40" color="primary" @click="addEmail"> Adicionar </v-btn>
    </div>

    <div class="d-flex justify-end align-center px-1 py-2">
      <mf-icon small class="mr-1">info</mf-icon>
      <p class="ma-0 texto-corrido">Alterações realizadas na lista abaixo impactam apenas esse envio.</p>
    </div>

    <v-data-table
      fixed-header
      disable-pagination
      hide-default-footer
      :headers="headers"
      :height="computedEmails.length > maxEmailsToScroll ? scrollHeight : ''"
      :items="computedEmails"
      no-data-text="Nenhum e-mail adicionado"
    >
      <template v-slot:header.email="{ header }">
        <span>E-mail</span>
      </template>
      <template v-slot:header.clear="{ header }">
        <delete-email v-if="emails.length > 0" remove-all-emails @remove-email="clearList" />
      </template>
      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{ item }}</td>
          <td class="text-right">
            <delete-email @remove-email="removeEmail(index)" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { required, email } from 'vuelidate/lib/validators'
import { validEmail } from '@/helpers/validators.js'
import DeleteEmail from './DeleteEmail'

export default {
  name: 'IncludeEmailsTable',
  components: {
    DeleteEmail
  },
  props: {
    emails: {
      type: Array,
      default: () => []
    },
    scrollHeight: {
      type: String,
      default: '240px'
    },
    maxEmailsToScroll: {
      type: Number,
      default: 4
    }
  },
  data: () => ({
    newEmail: '',
    errorEmail: '',
    defaultEmails: []
  }),
  computed: {
    computedEmails: {
      get() {
        return this.emails
      },
      set(value) {
        this.$emit('update:emails', value)
      }
    },
    headers() {
      return [
        {
          text: 'E-mail',
          width: '70%',
          value: 'email',
          sortable: false
        },
        {
          text: 'Limpar lista',
          value: 'clear',
          width: '30%',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  watch: {},
  methods: {
    isValid() {
      this.$v.$reset()
      this.$v.$touch()

      if (!this.computedEmails.length) {
        this.errorEmail = 'Nenhum e-mail adicionado'
        this.$snackbar({ message: 'Preencha todos os campos corretamente.', snackbarColor: 'error' })
        return false
      } else {
        this.errorEmail = ''
        return true
      }
    },
    removeEmail(index) {
      const list = cloneDeep(this.computedEmails)
      list.splice(index, 1)
      this.computedEmails = list
    },
    clearList() {
      this.computedEmails = []
    },
    addEmail() {
      this.$v.$reset()
      this.$v.$touch()
      if (!this.$v.newEmail.required) {
        this.errorEmail = 'Este campo é obrigatório'
        return
      }
      if (!this.$v.newEmail.email || !this.$v.newEmail.validEmail) {
        this.errorEmail = 'E-mail inválido'
        return
      }
      if (this.computedEmails.includes(this.newEmail.trim().toLowerCase())) {
        this.errorEmail = 'E-mail já adicionado'
        return
      } else {
        this.computedEmails.unshift(this.newEmail.trim().toLowerCase())
      }

      this.errorEmail = ''
      this.newEmail = ''
    }
  },
  validations: {
    newEmail: {
      required,
      email,
      validEmail
    }
  }
}
</script>

<style lang="scss" scoped>
.include-emails-container {
  padding: 16px 24px 10;
}
.email-title {
  color: #31363b;
  font-size: 18px;
}

.inputs-container {
  gap: 12px;
}

::v-deep th,
::v-deep td {
  font-size: 14px !important;
  color: #31363b !important;
  height: 40px;
}
</style>
