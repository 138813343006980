var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"748px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('mf-button',{attrs:{"text":"","color":"marca-0-l40","label":"Exportar dados","icon":"download"},nativeOn:{"click":function($event){return on.click($event)}}})]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',[_c('span',[_vm._v("Exportar dados de ofertas")])]),_c('v-card-subtitle',[_vm._v(" Relatório dos dados visualizados na tabela, de acordo com os filtros selecionados. ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('span',[_vm._v("Escolha o formato do arquivo a ser exportado:")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.fileFormat),callback:function ($$v) {_vm.fileFormat=$$v},expression:"fileFormat"}},[_c('v-radio',{attrs:{"label":"Formato .csv","value":"CSV"}}),_c('v-radio',{attrs:{"label":"Formato .xlsx","value":"XLSX"}})],1),_c('include-emails-table',{ref:"includeEmailsTable",attrs:{"emails":_vm.emails,"max-emails-to-scroll":4,"scroll-height":"192px"},on:{"update:emails":function($event){_vm.emails=$event}}})],1),_c('mf-action-buttons',{staticClass:"mb-3 mr-3",attrs:{"primary-button":{
        text: 'Exportar',
        action: _vm.exportLog,
        isDisabled: _vm.emails.length <= 0,
        isVisible: true,
        isLoading: _vm.loading
      },"cancel-button":{
        text: 'Fechar',
        action: function () { return (_vm.dialog = false); },
        isDisabled: false,
        isVisible: true,
        isLoading: _vm.loading
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }